import { InlineValue, useContent, usePageContent, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import {
  Box,
  Cell,
  Collapse,
  Container,
  Flex,
  Grid,
  Img,
  Link,
  Stack,
  Strong,
  Typography
} from "@xcorejs/ui";
import InnovationBox, { SkeletonInnovationBox } from "components/product/InnovationBox";
import SpecificationBox from "components/product/SpecificationBox";
import { FC, useEffect, useMemo, useState } from "react";
import { Product, ProductDetailPage, Products } from "xcore/types";

import { useLayout } from "xcore";
import { useGallery } from "./data";
import ProductTag from "components/product/ProductTag";
import { Localized, Richtext } from "@appiodev/xcore-core";
import RobeRichtext from "components/RobeRichtext";
import { hasStringField } from "utils/migration";
import { RobeLightBoxButton } from "./ProductPictures";
import { Skeleton } from "@mui/joy";
import RobeLoader from 'components/RobeLoader';

const ProductIntro: FC = () => {
  const [{ values, id }, { value, localize, stringify, file, richtextToString }] = usePageContent<Product>();
  const [productDetail] = useContent<[ProductDetailPage]>();
  const { data } = useXcoreQuery(cms => cms.content.list<Products>("products", {
    fields: ["newIconText"]
  }));
  const { general } = useLayout();

  const imagesPngs = useMemo(() =>
    values.images.map((i) => file(i, { width: 1920, height: 1920, withoutEnlargement: true, format: "png" })),
  [file, values.images]);

  const imagesJpegs = useMemo(() =>
    values.images.map((i) => file(i, { width: 1920, height: 1920, withoutEnlargement: true, format: "jpeg" })),
  [file, values.images]);

  const [openLightbox] = useGallery({
    fitToPageContent: true,
    disableScroll: true,
    hideProductSubHeader: true,
    images: imagesJpegs,
    createButtons: (offset: number) => {
      return [
        <RobeLightBoxButton
          key="png"
          href={imagesPngs[offset]}
        >
          {stringify(productDetail.values.downloadOriginalImageButtonText)}
        </RobeLightBoxButton>,
        <RobeLightBoxButton
          key="jpg"
          href={imagesJpegs[offset]}
        >
          {stringify(productDetail.values.downloadJPGButtonText)}
        </RobeLightBoxButton>
      ];
    }
  });
  const [showDescription, setShowDescription] = useState(false);

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false); // Shows debounced loading if image is still being downloaded
  const timeUntilShowLoading = 300; // in ms

  useEffect(() => {
    if (isImageLoading) { // Show loading with debounce
      const timeout = setTimeout(() => setShowLoading(true), timeUntilShowLoading);

      return () => clearTimeout(timeout);
    }

    setShowLoading(false); // Do not show loading if image is loaded
  }, [isImageLoading]);

  const discontinued = value(values.discontinuedGlob) || localize(value(values.discontinuedReg));
  const newIcon = value(values.newIcon);
  const ipCertifiedIcon = value(values.ipCertifiedIcon);
  const textTag = stringify(values.textTag);

  return (
    <Box id={"#" + stringify(productDetail.values.anchorAbout)}>
      <Box id={stringify(productDetail.values.anchorAbout)} transform={{ _: "translateY(-6rem)", md: "translateY(-14rem)" }} />
      <Container
        flexDirection="column"
        py={{ _: "3rem", sm: "5rem" }}
      >
        <Grid columns={["1fr", "1fr", "1fr", "repeat(12, 1fr)"]} rows="auto" gap="3rem">
          <Cell column={{ sm: "1/6" }}>
            <Box>
              <Flex flexDirection="column" alignItems="center" justifyContent="center" display={{ _: "flex", sm: "none" }} mb="2rem">
                <Typography variant="h1" textAlign="center">
                  <InlineValue value={value(values.name)} />
                </Typography>
                <Flex>
                  {discontinued && (
                    <ProductTag mt="1rem" variant="gray">
                      {stringify(general.values.discontinued)}
                    </ProductTag>
                  )}
                  {newIcon && <ProductTag mr="1rem" mt="1rem" variant="new">{stringify(data?.contents[0].values.newIconText)}</ProductTag>}
                  {ipCertifiedIcon && <ProductTag mr="1rem" mt="1rem" variant="ipCertified">IP65</ProductTag>}
                  {textTag && <ProductTag mt="1rem" variant="gray">{textTag}</ProductTag>}
                </Flex>
                {values.subheadline && (
                  <Box mt="2rem">
                    <RobeRichtext
                      value={values.subheadline}
                      _paragraph={{ fontSize: "2.4rem", lineHeight: "3.6rem", color: "text", fontWeight: 600 }}
                    />
                  </Box>
                )}
              </Flex>
              {/* Loading logic - image has to be in DOM to fire its onLoad function, so the components have display logic instead of using conditional rendering */}
              {/* <RobeLoader
                display={showLoading ? "flex" : "none"}
                width="440px"
                ml="1rem"
                maxWidth="100%"
                height="470px"
              /> */}
              <Img
                // display={showLoading ? "none" : "block"}
                width="100%"
                maxWidth={{ _: "31.5rem", sm: "45rem" }}
                mx="auto"
                src={file(values.images[0], { width: 452, height: 485 })}
                srcSet={`${file(values.images[0], { width: 452, height: 485 })} 1x, ${file(values.images[0], { width: 452, height: 485, enlargement: 2 })} 2x`}
                alt={stringify(values.name) + " | ROBE lighting"}
                cursor="pointer"
                onClick={() => openLightbox(0)}
                loading="lazy"
                onLoad={() => setIsImageLoading(false)}
              />
            </Box>
          </Cell>
          <Cell column={{ sm: "6/13" }}>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              display={{ _: "none", sm: "flex" }}
              mb="2rem"
            >
              <Typography variant="h1">
                <InlineValue value={value(values.name)} />
              </Typography>

              <Flex>
                {discontinued && (
                  <ProductTag mt="1rem" variant="gray">
                    {stringify(general.values.discontinued)}
                  </ProductTag>
                )}
                {newIcon && <ProductTag mr="1rem" mt="1rem" variant="new">{stringify(data?.contents[0].values.newIconText)}</ProductTag>}
                {ipCertifiedIcon && <ProductTag mr="1rem" mt="1rem" variant="ipCertified">IP65</ProductTag>}
                {textTag && <ProductTag mt="1rem" variant="gray">{textTag}</ProductTag>}
              </Flex>
              {values.subheadline && !isRichtextEmpty(localize(value(values.subheadline))) && (
                <Box mt="2rem">
                  <RobeRichtext
                    value={values.subheadline}
                    _paragraph={{ fontSize: "2.4rem", lineHeight: "3.6rem", color: "text", fontWeight: 600 }}
                    _strong={{ color: "darkGray" }}
                  />
                </Box>
              )}
            </Flex>

            <Box>
              <Typography variant="lead" textAlign={{ _: "center", sm: "left" }} pb="2rem">
                {hasStringField(value(values.intro))
                  ? <InlineValue value={stringify(values.intro as unknown as Localized<string>)} />
                  : richtextToString(values.intro) && (
                    <Box>
                      <RobeRichtext
                        value={value(values.intro)}
                        _paragraph={{ fontSize: "2.4rem", lineHeight: "3.6rem", color: "text" }}
                        _strong={{ color: "darkGray" }}
                      />
                    </Box>
                  )}
                {!value(values.showDescriptionExpanded) && (
                  !showDescription && richtextToString(values.description) && (
                    <Link
                      as="span"
                      onClick={() => setShowDescription(!showDescription)}
                      fontSize="inherit"
                      pt="2rem"
                      borderBottom="1px solid #e00034"
                      _hover={{ borderBottom: "1px solid transparent" }}
                      lineHeight="2.6rem"
                    >
                      {stringify(general.values.readMore)}...
                    </Link>
                  )
                )}
              </Typography>

              {richtextToString(values.description) && (
                <Collapse open={showDescription || value(values.showDescriptionExpanded)}>
                  <Typography variant="lead" textAlign={{ _: "center", sm: "left" }}>
                    <RobeRichtext
                      value={values.description}
                      _liClassName="product-description-li"
                      _strong={{ color: "darkGray" }}
                    />
                    {showDescription && (
                      <Link
                        as="span"
                        onClick={() => setShowDescription(!showDescription)}
                        fontSize="inherit"
                        pt="2rem"
                        borderBottom="1px solid #e00034"
                        _hover={{ borderBottom: "1px solid transparent" }}
                      >
                        {stringify(general.values.readLess)}
                      </Link>
                    )}
                  </Typography>
                </Collapse>
              )}
            </Box>

            <Grid columns={["1fr", "1fr", "1fr", "repeat(7, 1fr)"]} rows="auto" gap="3rem" mt="4rem">
              <Cell column={{ sm: "1/5" }}>
                <Stack direction="column" gap="1rem" px={{ _: "1.5rem", sm: "0" }}>
                  {values.paramsOrFeatures?.map((p, i) => (
                    <SpecificationBox
                      key={i}
                      name={stringify(p.type?.title)}
                      description={(
                        <RobeRichtext value={p.description} _strong={{ color: "darkGray" }} />
                      )}
                      icon={file(p.type?.values?.image, { width: 50, height: 50 })!}
                    />
                  ))}
                </Stack>
              </Cell>
              <Cell column={{ sm: "5/8" }}>
                {values.innovations && (
                  <Flex alignItems={{ _: "center", sm: "flex-start" }} flexDirection="column">
                    <Strong display="block" mb="1rem" lineHeight="2rem">{stringify(productDetail.values.innovation)}</Strong>
                    <Flex flexWrap="wrap" justifyContent={{ _: "center", sm: "flex-start" }} style={{ gap: "0.9rem" }}>
                      {values.innovations.length
                        ? values.innovations.map(i => (
                          <InnovationBox
                            key={value(i.id)}
                            innovation={value(i)}
                            mt="1rem"
                            openAs="link"
                            popover
                            backto={id}
                          />
                        ))
                        : Array(8).fill(null).map((_, i) => <SkeletonInnovationBox key={i} />) }
                    </Flex>
                  </Flex>
                )}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductIntro;

const isRichtextEmpty = (r: Richtext | undefined) => {
  if (!r) return true;
  return r.value.length === 0 && r.links.length === 0 && Object.keys(r.content).length === 0;
};
